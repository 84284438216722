import * as React from "react";
import { Component } from "react";
import HTML5Video from "../global/video.js";
import ImprovingAccess from "../../images/helping-break-down-barriers.jpg";
import ImprovingAccessMobile from "../../images/helping-break-down-barriers-mobile.jpg";
import PressPlay from "../../images/press-play-logo.png";
import StopTheVirus from "../../images/Stop-the-virus-logo.png";
import HealthySexual from "../../images/healthysexual-logo.png";
import CorporateGiving from "../../images/Support-and-resources-together.jpg";
import CorporateGivingMobile from "../../images/Support-and-resources-together-mobile.jpg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackToTop from "../global/BackToTop.js";
import References from "../global/references.js";
import $ from "jquery";

let VideoSrc =
  "https://player.vimeo.com/progressive_redirect/playback/917243390/rendition/1080p/file.mp4?loc=external&signature=d6a5046afc12131f66127e4440c72997ee9e4cff22588721f80e0c8f412586e8";

class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
    };
  }
  handleClose = (show) => {
    this.setState({ show: false });
  };
  handleShow = (show) => {
    this.setState({ show: true });
  };
  componentDidMount = () => {
    $("[data-jump-link").on("click", (e) => {
      let destination = $(e.currentTarget).attr("data-jump-link");
      let destinationElement = $(
        '[data-jump-destination="' + destination + '"]'
      );
      let position = $(destinationElement).offset().top;
      let offset = $(window).width() <= 574 ? 100 : 30;
      window.scrollTo({ top: position - offset, behavior: "smooth" });
    });
  };

  render() {
    return (
      <div
        className={`
        ${this.props.show ? "d-block" : "d-none"}
        about
        `}
      >
        <Container
          fluid
          className="hero about"
          role="img"
          aria-label="Together image with 5 people together, basking in the sun, some with eyes closed, full color"
        />
        <Container className="px-0">
          <Row>
            <Col xs={10} className="mx-auto">
              <div className="hero-callout all-together">
                <h1 className="title">We Are All In This Together</h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="px-0">
          <Row>
            <Col xs={10} className="mx-auto pt-0">
              <Container>
                <Row>
                  <Col xs={12} className="p-0 mb-standard">
                    <p>
                      When it comes to helping{" "}
                      <a href="/landscape/ehe">
                        end the HIV epidemic
                      </a>
                      , we’re all in this together. Not just for one, but for
                      the many. We recognize our role within the HIV community
                      and that by working together with community organizations,
                      allied healthcare professionals, and advocates every day,
                      we can help improve the lives of everyone living with HIV,
                      and for those affected by HIV. We're dedicated to
                      standing with the HIV community, advancing research and
                      development, and supporting organizations making
                      meaningful change to help all those impacted by HIV live
                      longer, healthier lives.{" "}
                    </p>
                    <figure className="pt-3 mt-3">
                      <HTML5Video
                        handleClose={this.handleClose}
                        handleShow={this.handleShow}
                        show={this.state.show}
                        thumbnail={"/All-Together-Video-Thumbnail.jpg"}
                        url={'https://player.vimeo.com/progressive_redirect/playback/1006011773/rendition/1080p/file.mp4?loc=external&signature=766e19f52d4ffc4dc70973ef57a78376b7ca625fe5e1ee24abbde6bd6602a889'}
                        videoTrackLabel="What We Live For"
                      />

                      <img
                        onClick={this.handleShow}
                        src={"/All-Together-Video-Thumbnail.jpg"}
                        alt="Video Thumbnail: GILEAD HIV What We Live For"
                        className="video w-100 mb-2"
                      />
                      <figcaption className="font-italic pt-1 pb-3">
                        Watch to see how it takes all of us in the HIV community
                        working together to overcome the challenges that stand
                        in the way of making progress in HIV.
                      </figcaption>
                    </figure>
                    <div className="tag-row about text-center">

                      <div className="button secondary large mr-3 mb-3" data-jump-link="pioneering-science">
                        Pioneering Science & Innovation
                      </div>
                      <div className="button secondary large mr-3 mb-3" data-jump-link="improving-access">
                        Expanding Access to Care
                      </div>
                      <div className="button secondary large mr-3 mb-3" data-jump-link="corporate-giving">
                        Corporate Giving
                      </div>
                      <div className="button secondary large mr-3 mb-3" data-jump-link="providing-education">
                        Providing Education
                      </div>
                      <div
                        className="button secondary large"
                        data-jump-link="fostering-community"
                      >
                        Working Together to Help End the Hiv Epidemic
                      </div>

                    </div>
                    
                  </Col>
                  <Col xs={12} className="section mb-standard">
                    <h2
                      data-jump-destination="pioneering-science"
                      className="title"
                    >
                      <span className="eyebrow">
                        Pioneering Science&nbsp;
                        <span className="d-block-inline">& Innovation</span>
                      </span>
                      <span className="d-block mt-3">
                        ALL IN FOR THE ADVANCEMENT OF PREVENTION, TREATMENT, AND
                        A CURE
                      </span>
                    </h2>
                    <Container className="px-0">
                      <Row>
                        <Col xs={12}>
                          <p>
                            We have been committed to leading advancements in
                            medicine since the beginning of the HIV epidemic.
                            Early on, HIV treatments were complex and
                            burdensome, sometimes requiring more than 20 pills a
                            day.<sup>1</sup> Recognizing this unmet need, we
                            worked to develop treatments that were one or two
                            pills a day. This approach led to the development of
                            multiple therapies for the treatment of HIV. We have
                            also launched medicines used as pre-exposure
                            prophylaxis (PrEP) to help prevent HIV transmission.
                            And together with the community, we're working to
                            make sure that prevention means more than just
                            preventing the acquisition of HIV with medicine.
                          </p>
                          <p className="mb-0">
                            Although HIV is now considered a chronic, manageable
                            disease, there is no cure for HIV.<sup>2,3</sup> We
                            are committed to driving forward research in pursuit
                            of a cure to help put HIV and its impact in the
                            past. Building on our history of curing difficult
                            diseases, we are working to find a cure for HIV in
                            our own labs and with more than 100 research
                            partners. We continue to focus on increasing HIV
                            testing and innovating across the HIV care continuum
                            with the hopes that, when coupled with the ongoing
                            efforts to break down barriers to care wherever they
                            are, the age of HIV can one day come to an end.
                          </p>
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                  <Col xs={12} className="section mb-standard">
                    <h2
                      data-jump-destination="improving-access"
                      className="title"
                    >
                      <span className="eyebrow">Expanding Access to Care</span>
                      <span className="d-block mt-3">
                        HELPING BREAK DOWN BARRIERS
                      </span>
                    </h2>
                    <img
                      className="mx-auto pb-3 mb-1 d-none d-md-block w-85"
                      src={ImprovingAccess}
                      alt="Helping Break Down Barriers' Together image, 3 people close together, full color"
                    />
                    <img
                      className="mx-auto pb-3 mb-1 d-block d-md-none w-85"
                      src={ImprovingAccessMobile}
                      alt="Helping Break Down Barriers' Together image, 3 people close together, full color"
                    />
                    <p>
                      People impacted by HIV have historically faced a wide
                      range of systemic barriers that stand in the way of
                      obtaining equitable care.<sup>4</sup> Race, sexual
                      identity, geographic location, and socioeconomic status
                      are among many factors across which we see vast,
                      persistent health disparities caused by stigma, racism,
                      unequal access to healthcare, and other barriers.
                      <sup>5</sup> By working together with advocacy groups and
                      community organizations, we’re committed to breaking down
                      barriers to inequality by supporting HIV communities,
                      exposing and removing the underlying social and systemic
                      factors, creating policy reform, and supporting grassroots
                      activism.
                    </p>
                    <p>
                      In the United States, we’ve created programs to offer
                      information and resources to help people connect with the
                      care they need. The Gilead Advancing Access<sup>®</sup>{" "}
                      program helps eligible individuals, whether they are
                      insured or uninsured, understand their coverage and
                      identify financial support options available to them.
                      Through the Advancing Access Patient Assistance Program,
                      eligible patients without insurance may be able to receive
                      the Gilead HIV treatment and HIV prevention medications
                      their healthcare professional has prescribed free of
                      charge. For individuals who have commercial insurance, but
                      need help paying for their out-of-pocket costs, the
                      Advancing Access Co-pay Coupon Program* provides co-pay
                      assistance to eligible patients for their prescribed
                      Gilead HIV medications.
                    </p>
                    <p>
                    To help make it easier for people to access the medicine they need around the world, in 2003, we launched programs to provide access to our medicines in 140 low- and middle-income countries. Since 2018, more than 12 million people have benefited from Gilead medicines in low- and middle-income countries. We also know it will take more than medicine to end the HIV epidemic and that’s why we work with public health officials, community advocates, researchers, doctors, and patients to understand barriers and opportunities in communities impacted by HIV.
                    </p>
                    <p class="footnote"><span className="sup">*</span>For commercially insured eligible patients only.
                      Restrictions apply. See terms and conditions at{" "}
                      <a
                        href="https://www.GileadAdvancingAccess.com" style={{textDecoration: 'underline'}}
                      >
                        www.GileadAdvancingAccess.com
                      </a></p>
                  </Col>
                  <Col xs={12} className="section corporate-giving mb-standard">
                    <h2
                      data-jump-destination="corporate-giving"
                      className="title text-uppercase "
                    >
                      <span className="eyebrow">Corporate Giving</span>
                      <span className="d-block mt-3">
                        OFFERING SUPPORT AND RESOURCES
                      </span>
                    </h2>
                    <img
                      className="d-none d-md-block mx-auto pb-3 mb-1 w-85"
                      src={CorporateGiving}
                      alt="Offering Support and Resources' Together image, two men hugging, looking off into distance, full color"
                    />
                    <img
                      className="d-block d-md-none mx-auto pb-3 mb-1 w-85"
                      src={CorporateGivingMobile}
                      alt="Offering Support and Resources' Together image, two men hugging, looking off into distance, full color"
                    />
                    <p>
                      The work being done throughout the HIV community to create
                      meaningful change inspires us to help build the community
                      from within, amplify those everyday efforts, and address
                      the barriers to care. As part of this commitment, through
                      our grantee programs, we support patient advocates,
                      healthcare professionals, and nonprofit groups around the
                      world that work tirelessly in local communities to help
                      improve the lives of people affected by HIV. We have been
                      recognized as one of the top private funders of
                      HIV-related programs.<sup>6</sup> In 2022, Gilead:
                    </p>
                    <ul className="mb-0">
                      <li>
                        Distributed 948 HIV-related grants totaling just over {" "}
                        <span className="font-heavy font-red">
                          $177 million
                        </span>{" "}
                        for HIV-related projects
                      </li>
                      <li>
                        Was recognized by Funders Concerned about AIDS (FCAA),
                        as the{" "}
                        <span className="font-heavy font-red">
                          #2 philanthropic funder of HIV-related programs globally and #1 in the United States
                        </span>
                      </li>
                      <li>
                        Received top recognition as the{" "}
                        <span className="font-heavy font-red">#1 funder</span>{" "}
                        of programs providing support for:
                        <ul className="solid">
                          <li>Advocacy</li>
                          <li>General operating support</li>
                          <li>Sex workers</li>
                          <li>Transgender communities</li>
                          <li>Gay, bisexual, and other men who have sex with men</li>
                        </ul>
                      </li>
                    </ul>
                  </Col>
                  <Col xs={12} className="section mb-0 mb-md-4 pb-2 pb-md-4">
                    <h2
                      data-jump-destination="providing-education"
                      className="title text-uppercase "
                    >
                      <span className="eyebrow">Providing Education</span>
                      <span className="d-block mt-3">
                        SHARING INSIGHTS WITH THE COMMUNITY
                      </span>
                    </h2>
                    <p>
                      Together we can help end the HIV epidemic for everyone,
                      everywhere. A key to reaching that goal is HIV education.
                      With the belief that it is important to start educational
                      work from the ground up, we helped to create a team of
                      Community Liaisons to work with community organizations
                      and healthcare professionals at the local level, taking
                      the time to understand the needs of each community and
                      provide tailored HIV educational resources to help end the
                      HIV epidemic.
                    </p>
                    <p>
                      In addition, knowledge-sharing between leaders can help us
                      support one another in our common goal of helping end the
                      HIV epidemic. To this end, we have created educational
                      programs aimed at addressing emerging trends and issues
                      impacting the HIV epidemic to provoke thought leadership
                      in the HIV space. The Blueprints for Success Training
                      Institutes is a series on virtual skill-building programs
                      for leaders in HIV to help equip them with the knowledge
                      and tools to address new challenges around HIV care and
                      prevention. Examples of topics covered include Cultural
                      Humility, Institutional Stigma, and Telemedicine.
                    </p>
                    <p>
                      We’ve also created educational sites that feature programs
                      designed to educate and share facts about HIV to help
                      people protect themselves and each other, including:
                    </p>
                  </Col>
                </Row>

                <Row className="justify-content-between">
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 edu-card mx-auto mx-md-0 mb-4 mb-md-0"
                  >
                    <a target="_blank" href="https://www.hivtestnow.com">
                      <img className="pb-3" src={PressPlay} alt="Press Play logo, click to visit the website" />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1 hover-underline">
                          HIV Test Now
                        </span>
                      </p>
                    </a>
                    <p className="mb-0">
                      HIV testing is an important part of self-care. HIV Test
                      Now is an educational resource to help people get tested
                      and know their HIV status.
                    </p>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 edu-card mx-auto mx-md-0 mb-4 mb-md-0"
                  >
                    <a target="_blank" href="https://www.helpstopthevirus.com">
                      <img className="pb-3" src={StopTheVirus} alt="Image of Stop the Virus logo. Working together to help stop the virus. Click to visit their website" />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1 hover-underline">
                          Help Stop the Virus
                        </span>
                      </p>
                    </a>
                    <p className="mb-0">
                        HIV education for everyone, showing how prevention,
                        testing, and treatment work together to help stop HIV in
                        our bodies and communities, featuring videos and other
                        resources.
                      </p>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 edu-card mx-auto mx-md-0 mb-4 mb-md-0 "
                  >
                    <a target="_blank" href="https://www.healthysexuals.com/">
                      <img className="pb-3" src={HealthySexual} alt="Image of Healthysexual ® Logo, click to visit their website" />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">Healthysexual</span>
                          <sup>&reg;</sup>
                        </span>
                      </p>
                    </a>
                    <p>
                        Teaching individuals how to protect their sexual health
                        through tips and other materials, with a focus on
                        everyone knowing about their HIV prevention options.
                      </p>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 edu-card mx-auto mx-md-0"
                  />
                </Row>

                <Row>
                  <Col xs={12} className="section mb-4 pb-4 px-0">
                    <h2
                      data-jump-destination="fostering-community"
                      className="title text-uppercase "
                    >
                      WORKING TOGETHER TO HELP END THE HIV EPIDEMIC
                    </h2>
                    <p>
                      None of us can do this work alone—seeing the age of HIV
                      come to an end is a collective mission. That’s why we work
                      closely with healthcare providers, researchers, academics,
                      policymakers, advocates, activists, people living with
                      HIV, and those affected by HIV.
                    </p>
                    <p>
                      Working with the community toward our collective, common
                      goal of ending the HIV epidemic, we’re supporting the
                      innovative work of grassroots organizations to help
                      improve access to care by eliminating barriers and
                      advancing education. Below are some of our key
                      partnerships and initiatives in HIV:
                    </p>
                  </Col>
                </Row>

                <Row className="justify-content-between mb-0 mb-md-4">
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a target="_blank" href="https://www.gilead.com/purpose/health-equity">
                      <img
                        className="pb-3"
                        src={"/Advancing-health-equity-logo.jpg"}
                        alt="Image of Advancing Health Equity logo, click to visit their website"
                      />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1 hover-underline">
                          Advancing Health Equity at Gilead
                        </span>
                      </p>
                    </a>
                    <p>
                        We leverage our decades of clinical expertise, scientific innovation, and deep relationships with community partners to continue advancing equity for people with the greatest unmet needs.  Our health equity efforts enable us to reach more people with our innovative therapies, especially those in marginalized and disinvested communities where individuals are disproportionately impacted by the diseases we seek to prevent, treat and cure. We have identified three global health equity priorities that will help us achieve our mission of creating a healthier world for all people: helping to enable access to life-changing medicines, delivering innovation to reduce inequities, and igniting social impact to advance human rights for communities.
                      </p>
                  </Col>

                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a target="_blank" href="https://aidsvu.org/">
                      <img
                        className="pb-3"
                        src={"/aidsvu-logo.png"}
                        alt="Image of AIDSVu.org Logo, click to visit their website"
                      />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1 hover-underline">
                          AIDSVu.org
                        </span>
                      </p>
                    </a>
                    <p>
                        Launched in partnership between Gilead and Emory
                        University’s Rollins School of Public Health and the
                        Center for AIDS Research at Emory University (CFAR),
                        AIDSVu.org is an interactive mapping tool that helps
                        users visualize HIV data at the national, regional, and
                        local levels, and provides location services for HIV
                        testing, prevention, and care.
                      </p>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0"
                  >
                    <a target="_blank" href="https://www.gileadcompass.com/">
                      <img
                        className="pb-3"
                        src={"/compass-initiative-logo.png"}
                        alt="Image of COMPASS Initiative® Logo, click to visit their website"
                      />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">
                            COMPASS Initiative
                          </span>
                          <sup>&reg;</sup>
                        </span>
                      </p>
                    </a>
                    <p>
                        (COMmitment to Partnership in Addressing HIV/AIDS in
                        Southern States): Gilead's over $100M, 10-year
                        commitment to help address the HIV epidemic in the US
                        South.
                      </p>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a
                      target="_blank"
                      href="https://www.gilead.com/purpose/partnerships-and-community/hiv-age-positively"
                    >
                      <img
                        className="pb-3"
                        src={"/hiv-age-positively-logo.png"}
                        alt="Image of HIV Age Positively®, click to visit their website"
                      />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">
                            HIV Age Positively
                          </span>
                          <sup>&reg;</sup>
                        </span>
                      </p>
                    </a>
                    <p>
                        Aims to support programs focused on improving the
                        quality of life and health of those aging with HIV, and
                        making an impact on HIV policy.
                      </p>
                  </Col>
                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a target="_blank" href="https://radianhiv.org/">
                      <img
                        className="pb-3"
                        src={"/radian-logo.png"}
                        alt="Image of Radian® Logo, click to visit their website"
                      />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">RADIAN</span>
                          <sup>&reg;</sup>
                        </span>
                      </p>
                    </a>
                    <p>
                        A partnership between Gilead Sciences and the Elton John
                        AIDS Foundation to address new HIV infections and deaths
                        from HIV-related illnesses in Eastern Europe and Central
                        Asia.
                      </p>
                  </Col>

                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0 mb-4"
                  >
                    <a
                      target="_blank"
                      href="https://www.gilead.com/purpose/partnerships-and-community/transcend"
                    >
                      <img
                        className="pb-3"
                        src={"/transcend-logo.png"}
                        alt="Image of TRANScend® Logo, click to visit their website"
                      />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">TRANScend</span>
                          <sup>&reg;</sup>
                        </span>
                      </p>
                    </a>
                    <p>
                        Supports trans-led organizations working to reduce the
                        impact of HIV within non-binary and transgender
                        communities.
                      </p>
                  </Col>

                  <Col
                    xs={10}
                    lg={4}
                    className="px-0 partner-card mx-auto mx-md-0"
                  >
                    <a target="_blank" href="https://www.gilead.com/responsibility/giving-at-gilead/corporate-giving/strategic-initiatives/zeroing-in-ending-the-hiv-epidemic">
                      <img
                        className="pb-3"
                        src={"/WebZeroingIng.jpg"}
                        alt="Zeroing In: Ending the HIV Epidemic® logo, click to visit their website"
                      />
                      <p className="mb-0">
                        <span className="font-heavy font-red d-block pb-1">
                          <span className="hover-underline">
                            Zeroing In: Ending the HIV Epidemic
                          </span>
                          <sup>&reg;</sup>
                        </span>
                      </p>
                    </a>
                    <p>
                        Supports organizations and partners who are working to
                        increase the overall health and wellness of communities
                        most impacted by HIV and the COVID-19 pandemic.
                      </p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="section mb-3 px-0">
                    <h3 className="title text-uppercase ">
                      MOVING AHEAD, TOGETHER
                    </h3>
                    <p>
                      To keep forward momentum on all of these causes, we’ve
                      created this website as an educational resource for
                      emerging leaders and advocates in the HIV community who
                      are engaged in our shared work to help end the HIV
                      epidemic for everyone, everywhere.
                    </p>
                    <a href="/" className="button medium secondary">
                      Explore more
                    </a>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <Container className="px-0">
          <Row>
            <Col xs={12} className="px-0">
              <BackToTop />
            </Col>
          </Row>
        </Container>
        <References page={this.props.page} />
      </div>
    );
  }
}

export default Content;
