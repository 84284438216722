import * as React from "react";
import { Component } from 'react';
import Layout from '../components/global/layout.js'
import Content from '../components/about/content.js'
import '@fortawesome/fontawesome-free/css/all.css'; 
import '../sass/app.scss';

class About extends Component {
  render() {
    return (
      <>
        <Layout section="about" page="about">
          <Content section="about" page="about" />
        </Layout>
      </>
    )
  }
}

export default About;